import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SecondaryHero from "../../components/secondary-hero";
import AgmSection from "../../components/agm-section";

const AnnualGeneralMeetingPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			agm: allWpAgmReport(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					title
					agmReportFields {
						description
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						accounts {
							mediaItemUrl
						}
						report {
							mediaItemUrl
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Annual General Meeting",
				item: {
					url: `${siteUrl}/about-us/annual-general-meeting`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Annual General Meeting | Friends of Eastbourne Hospital"
				description="The Friends of Eastbourne Hospital’s AGM was held on Wednesday 24 November 2021 at Victoria Baptist Church in Eldon Road."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/annual-general-meeting`,
					title: "Annual General Meeting | Friends of Eastbourne Hospital",
					description:
						"The Friends of Eastbourne Hospital’s AGM was held on Wednesday 24 November 2021 at Victoria Baptist Church in Eldon Road.",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Annual General Meeting"
						description={
							<span>
								You can read the Chairman’s AGM Report and annual accounts for
								each year by clicking on the buttons below.
							</span>
						}
					/>
				</section>
				<section className="pb-5">
					<Container>
						{data.agm.nodes.map((report) => (
							<AgmSection agm={report} />
						))}
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default AnnualGeneralMeetingPage;
