import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";

const AgmSection = ({ agm }) => {
	return (
		<Row className="justify-content-center mb-xl-6  align-items-center ">
			<Col xl={12}>
				<Row className="justify-content-center mb-xl-6 align-items-center h-100 ">
					<Col xs={12} lg={5}>
						<GatsbyImage
							style={{ borderRadius: "5px" }}
							class="w-100 h-100 "
							image={
								agm.agmReportFields?.image?.localFile.childImageSharp
									.gatsbyImageData
							}
							alt={agm.agmReportFields?.image?.localFile.altText}
						/>
					</Col>
					<Col xs={12} lg={7} className=" py-5 py-xl-0 px-4  px-lg-7">
						<h2 className=" text-primary   display-4">{agm.title}</h2>
						<p className="text-start mt-5">{agm.agmReportFields.description}</p>
						<Row className="   text-white h-100 mt-5  align-items-center  ">
							<Col className="pl-0 ">
								<Button
									variant="primary"
									className=" py-3 w-100 w-md-auto me-4 text-white px-5 "
									target="_blank"
									rel="noreferrer"
									href={agm.agmReportFields.report.mediaItemUrl}
								>
									AGM Report
								</Button>
								<Button
									variant="outline-primary"
									target="_blank"
									rel="noreferrer"
									className=" py-3 w-100  w-md-auto mt-4 mt-md-0 px-4  "
									href={agm.agmReportFields.accounts.mediaItemUrl}
								>
									Annual Accounts
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AgmSection;
